// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-actions-boat-complete-js": () => import("./../../../src/pages/actions/boat/complete.js" /* webpackChunkName: "component---src-pages-actions-boat-complete-js" */),
  "component---src-pages-actions-boat-request-js": () => import("./../../../src/pages/actions/boat/request.js" /* webpackChunkName: "component---src-pages-actions-boat-request-js" */),
  "component---src-pages-actions-car-request-js": () => import("./../../../src/pages/actions/car/request.js" /* webpackChunkName: "component---src-pages-actions-car-request-js" */),
  "component---src-pages-actions-landing-personalised-villas-complete-js": () => import("./../../../src/pages/actions/landing/personalised-villas/complete.js" /* webpackChunkName: "component---src-pages-actions-landing-personalised-villas-complete-js" */),
  "component---src-pages-actions-landing-personalised-villas-request-js": () => import("./../../../src/pages/actions/landing/personalised-villas/request.js" /* webpackChunkName: "component---src-pages-actions-landing-personalised-villas-request-js" */),
  "component---src-pages-actions-service-request-js": () => import("./../../../src/pages/actions/service/request.js" /* webpackChunkName: "component---src-pages-actions-service-request-js" */),
  "component---src-pages-actions-villa-complete-js": () => import("./../../../src/pages/actions/villa/complete.js" /* webpackChunkName: "component---src-pages-actions-villa-complete-js" */),
  "component---src-pages-actions-villa-enquire-js": () => import("./../../../src/pages/actions/villa/enquire.js" /* webpackChunkName: "component---src-pages-actions-villa-enquire-js" */),
  "component---src-pages-actions-villa-request-js": () => import("./../../../src/pages/actions/villa/request.js" /* webpackChunkName: "component---src-pages-actions-villa-request-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-personalised-villas-js": () => import("./../../../src/pages/landing/personalised-villas.js" /* webpackChunkName: "component---src-pages-landing-personalised-villas-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-villa-js": () => import("./../../../src/pages/villa.js" /* webpackChunkName: "component---src-pages-villa-js" */),
  "component---src-pages-villas-js": () => import("./../../../src/pages/villas.js" /* webpackChunkName: "component---src-pages-villas-js" */),
  "component---src-pages-whats-open-js": () => import("./../../../src/pages/whats-open.js" /* webpackChunkName: "component---src-pages-whats-open-js" */),
  "component---src-templates-boat-js": () => import("./../../../src/templates/boat.js" /* webpackChunkName: "component---src-templates-boat-js" */),
  "component---src-templates-boats-js": () => import("./../../../src/templates/boats.js" /* webpackChunkName: "component---src-templates-boats-js" */),
  "component---src-templates-car-js": () => import("./../../../src/templates/car.js" /* webpackChunkName: "component---src-templates-car-js" */),
  "component---src-templates-cars-js": () => import("./../../../src/templates/cars.js" /* webpackChunkName: "component---src-templates-cars-js" */),
  "component---src-templates-concierge-js": () => import("./../../../src/templates/concierge.js" /* webpackChunkName: "component---src-templates-concierge-js" */),
  "component---src-templates-listing-category-js": () => import("./../../../src/templates/listingCategory.js" /* webpackChunkName: "component---src-templates-listing-category-js" */),
  "component---src-templates-listing-feature-js": () => import("./../../../src/templates/listingFeature.js" /* webpackChunkName: "component---src-templates-listing-feature-js" */),
  "component---src-templates-listing-js": () => import("./../../../src/templates/listing.js" /* webpackChunkName: "component---src-templates-listing-js" */),
  "component---src-templates-property-js": () => import("./../../../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

